@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,700');

.loadingIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.loadingIcon {
  animation-name: loading;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  width: 100px;
  height: 100px;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
