@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900');

:global .bcg-peach {
  background-color: #cdacc6;
}

:global .bcg-white {
  background-color: #fff;
}

:global .bcg-grey {
  background-color: #eee;
}

:global .bcg-light-grey {
  background-color: #f3f4f7;
}

:global .bcg-black {
  background-color: #222;
}

:global .border-wrapper {
  border: #d8d8d8 solid 1px;
}

:global .c-brand {
  color: #fb6a66;
}

:global .c-white {
  color: #fff;
}

:global .c-black {
  color: #222;
}

:global .c-black-60 {
  color: rgba(0, 0, 0, 0.6);
}

:global .c-light-grey {
  color: #ccc;
}

:global .case-low {
  text-transform: lowercase;
}

:global .case-caps {
  text-transform: capitalize;
}

:global .case-up {
  text-transform: uppercase;
}

:global .cur-pointer {
  cursor: pointer;
}

:global .no-pointer {
  pointer-events: none;
}

:global .d-inline {
  display: inline;
}

:global .d-block {
  display: block;
}

:global .d-none {
  display: none;
}

:global .d-flex {
  display: flex;
}

:global .fb-5 {
  flex-basis: 10%;
}

:global .fb-10 {
  flex-basis: 10%;
}

:global .fb-15 {
  flex-basis: 15%;
}

:global .fb-20 {
  flex-basis: 20%;
}

:global .fb-25 {
  flex-basis: 25%;
}

:global .fb-30 {
  flex-basis: 30%;
}

:global .fb-35 {
  flex-basis: 35%;
}

:global .fb-40 {
  flex-basis: 40%;
}

:global .fb-45 {
  flex-basis: 45%;
}

:global .fb-50 {
  flex-basis: 50%;
}

:global .fb-55 {
  flex-basis: 55%;
}

:global .fb-60 {
  flex-basis: 60%;
}

:global .fb-65 {
  flex-basis: 65%;
}

:global .fb-70 {
  flex-basis: 70%;
}

:global .fb-75 {
  flex-basis: 75%;
}

:global .fb-80 {
  flex-basis: 80%;
}

:global .fb-85 {
  flex-basis: 85%;
}

:global .fb-90 {
  flex-basis: 90%;
}

:global .fb-95 {
  flex-basis: 95%;
}

:global .fb-100 {
  flex-basis: 100%;
}

:global .ff-ns {
  font-family: 'Nunito Sans', sans-serif;
}

:global .ff-pds {
  font-family: 'Playfair Display', serif;
}

:global .flex-ais {
  align-items: start;
}

:global .flex-aic {
  align-items: center;
}

:global .flex-ai-stretch {
  align-items: stretch;
}

:global .flex-ais {
  align-items: flex-start;
}

:global .flex-aie {
  align-items: flex-end;
}

:global .flex-asfs {
  align-self: flex-start;
}

:global .flex-asc {
  align-self: center;
}

:global .flex-asfe {
  align-self: flex-end;
}

:global .flex-fdc {
  flex-direction: column;
}

:global .flex-fdr {
  flex-direction: row;
}

:global .flex-fdcr {
  flex-direction: column-reverse;
}

:global .flex-fdrr {
  flex-direction: row-reverse;
}

:global .flex-grow-1 {
  flex-grow: 1;
}

:global .flex-grow-2 {
  flex-grow: 2;
}

:global .flex-jc {
  justify-content: center;
}

:global .flex-jsa {
  justify-content: space-around;
}

:global .flex-jsb {
  justify-content: space-between;
}

:global .flex-jse {
  justify-content: space-evenly;
}

:global .flex-jfe {
  justify-content: flex-end;
}

:global .flex-jfs {
  justify-content: flex-start;
}

:global .flex-frw {
  flex-flow: row wrap;
}

:global .flex-frnw {
  flex-flow: row nowrap;
}

:global .fs-10 {
  font-size: 10px;
}

:global .fs-12 {
  font-size: 12px;
}

:global .fs-14 {
  font-size: 14px;
}

:global .fs-16 {
  font-size: 16px;
}

:global .fs-18 {
  font-size: 18px;
}

:global .fs-24 {
  font-size: 24px;
}

:global .fs-30 {
  font-size: 30px;
}

:global .fs-36 {
  font-size: 36px;
}

:global .fw-normal {
  font-weight: 400;
}

:global .fw-bold {
  font-weight: 700;
}

:global .fw-strong {
  font-weight: 900;
}

:global .float-r {
  float: right;
}

:global .float-l {
  float: left;
}

:global .lh-sm {
  line-height: 16px;
}

:global .lh-md {
  line-height: 18px;
}

:global .lh-lg {
  line-height: 24px;
}

:global .lh-50 {
  line-height: 50px;
}

:global .ls-1 {
  letter-spacing: 1px;
}

:global .ls-2 {
  letter-spacing: 2px;
}

:global .ls-4 {
  letter-spacing: 4px;
}

:global .pos-a {
  position: absolute;
}

:global .pos-f {
  position: fixed;
}

:global .pos-r {
  position: relative;
}

:global .pos-s {
  position: static;
}

:global .o-auto {
  overflow: auto;
}

:global .o-hidden {
  overflow: hidden;
}

:global .o-scroll {
  overflow: scroll;
}

:global .pad-h-4 {
  padding: 4px 0;
}

:global .pad-h-8 {
  padding: 8px 0;
}

:global .pad-h-16 {
  padding: 16px 0;
}

:global .pad-h-32 {
  padding: 32px 0;
}

:global .pad-w-4 {
  padding: 0 4px;
}

:global .pad-w-8 {
  padding: 0 8px;
}

:global .pad-w-16 {
  padding: 0 16px;
}
:global .pad-w-32 {
  padding: 0 32px;
}

:global .pad-0 {
  padding: 0;
}

:global .pad-4 {
  padding: 4px;
}

:global .pad-8 {
  padding: 8px;
}

:global .pad-16 {
  padding: 16px;
}

:global .pad-32 {
  padding: 32px;
}

:global .mar-a {
  margin: auto;
}

:global .mar-h-4 {
  margin: 4px 0;
}

:global .mar-h-8 {
  margin: 8px 0;
}

:global .mar-h-16 {
  margin: 16px 0;
}
:global .mar-w-4 {
  margin: 0 4px;
}

:global .mar-w-8 {
  margin: 0 8px;
}

:global .mar-w-16 {
  margin: 0 16px;
}

:global .mar-0 {
  margin: 0;
}

:global .mar-4 {
  margin: 4px;
}

:global .mar-8 {
  margin: 8px;
}

:global .mar-16 {
  margin: 16px;
}

:global .ta-left {
  text-align: left;
}

:global .ta-center {
  text-align: center;
}

:global .ta-right {
  text-align: right;
}

:global .ta-justify {
  text-align: justify;
}

:global .td-underline {
  text-decoration: underline;
}

:global .td-line-through {
  text-decoration: line-through;
}

:global .t-ws-nowrap {
  white-space: nowrap;
}

:global .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:global .h-0 {
  height: 0;
}

:global .h-100 {
  height: 100%;
}

:global .h-100-vh {
  height: 100vh;
}

:global .w-100 {
  width: 100%;
}

:global .w-100-vw {
  width: 100vw;
}

:global .w-10 {
  width: 10%;
}

:global .w-15 {
  width: 10%;
}

:global .w-20 {
  width: 20%;
}

:global .w-25 {
  width: 20%;
}

:global .w-30 {
  width: 30%;
}

:global .w-35 {
  width: 30%;
}

:global .w-40 {
  width: 40%;
}

:global .w-45 {
  width: 40%;
}

:global .w-50 {
  width: 50%;
}

:global .w-55 {
  width: 50%;
}

:global .w-60 {
  width: 60%;
}

:global .w-65 {
  width: 60%;
}

:global .w-70 {
  width: 70%;
}

:global .w-75 {
  width: 70%;
}

:global .w-80 {
  width: 80%;
}

:global .w-85 {
  width: 80%;
}

:global .w-90 {
  width: 90%;
}

:global .w-95 {
  width: 90%;
}

:global .w-100 {
  width: 100%;
}

:global .fb-10 {
  flex-basis: 10%;
}

:global .fb-20 {
  flex-basis: 20%;
}

:global .fb-30 {
  flex-basis: 30%;
}

:global .fb-40 {
  flex-basis: 40%;
}

:global .fb-50 {
  flex-basis: 50%;
}

:global .fb-60 {
  flex-basis: 60%;
}

:global .fb-70 {
  flex-basis: 70%;
}

:global .fb-80 {
  flex-basis: 80%;
}

:global .fb-90 {
  flex-basis: 90%;
}

:global .fb-100 {
  flex-basis: 100%;
}

@media (min-width: 900px) {
  :global h1 {
    margin: 0 0 30px;
  }
}

:global .naked-button {
  background: none;
  border: none;
}

:focus {
  outline: none;
}

:global .hor-rule {
  background-image: linear-gradient(
    270deg,
    #ffffff 0%,
    #dddddd 18.96%,
    #d0d0d0 52.42%,
    #e6e6e6 83.26%,
    #ffffff 100%
  );
  width: 90%;
  height: 1px;
  margin: 16px auto 32px auto;
}

:global .hor-rule.mar-h-8 {
  margin: 8px 0;
}
