@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,700');

.otp-page {
  background: url(../tem-bg-full.jpg) no-repeat center center;
  min-height: 600px;
  font-weight: 300;
  overflow: auto;
  background-size: cover;
  background-attachment: fixed;
}

.otp-page * {
  font-family: 'Nunito Sans';
  box-sizing: border-box;
}

.otp,
.otp {
  max-width: 588px;
  margin: 168px auto;
  padding: 48px 60px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  border: none;
}

.otp-success {
  max-width: 588px;
  margin: 168px auto;
  padding: 48px 60px;
  border-radius: 2px;
  border: none;
}

.otp .padding-top {
  padding-top: 14px;
}

.labelpair:not(:first-child) {
  margin-top: 10px;
}

.labelpair label {
  display: block;
  font-family: 'Nunito Sans';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  padding-bottom: 8px;
}

.form-description {
  font-weight: 500;
  color: #000000;
  font-family: 'Nunito Sans';
  padding: 8px 0;
  text-align: center;
}

.labelpair label span {
  font-weight: 700;
  margin-left: 5px;
}

.labelpair input {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #d0d0d0;
  background-color: #ffffff;
}

.labelpair-error input {
  box-sizing: border-box;
  border: 1px solid #cc484a;
  background-color: rgba(255, 91, 93, 0.05);
}

.labelpair input:focus {
  border: 1px solid #ccc;
}

.otp-button:disabled {
  background-color: #d0d0d0;
  border: none;
  color: #ececec;
  pointer-events: none;
}

.otp-button,
.otp-button {
  margin-top: 24px;
  font-size: 16px;
  padding: 15px;
  text-transform: uppercase;
  background: #fb6a66;
  border: none;
  color: #fff;
  width: 100%;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.button-font {
  font-family: 'Nunito Sans';
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 18px;
}

.otp-button:hover,
.otp-button:focus,
.otp-button:hover,
.otp-button:focus {
  background: #fa9c98;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.links .check input:focus {
  outline: dotted;
  outline-color: #999;
  outline-width: thin;
}

.links label {
  display: inline-block;
  font-size: 14px;
  margin-left: 5px;
  color: #444;
}

.links a,
.message a {
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  margin-left: auto;
  color: #444;
  border-bottom: 1px solid #ddd;
  transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  -moz-transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  -webkit-transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
}

.message a {
  font-size: inherit;
}

.message a:hover,
.message a:focus,
.links a:hover,
.links a:focus {
  border-bottom: 1px solid #444;
}

.bottom {
  margin: 45px auto 15px;
  text-align: center;
  color: #222;
  font-weight: 300;
  letter-spacing: 0.04em;
  font-size: 16px;
  word-break: break-word;
}

.bottom a {
  text-decoration: none;
  border-bottom: 1px solid #ddd;
  transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  -moz-transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  -webkit-transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  color: #222;
}

.bottom a:hover,
.bottom a:focus {
  border-bottom: 1px solid #444;
}

.otp .header,
.otp .header {
  color: #000000;
  font-family: 'Playfair Display';
  font-size: 36px;
  letter-spacing: 0;
  line-height: 42px;
  text-align: center;
  margin: 0 auto;
}

.otp .title,
.otp .description {
  color: #000000;
  font-family: 'Nunito Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin: 0 auto;
}

.otp .title-padding {
  padding: 24px 0;
}

.otp .description-padding {
  padding-bottom: 31px;
}

.otp .description .lost {
  font-weight: bold;
  text-decoration: none;
  color: #fb6a66;
}

.otp .description .lost:hover,
.otp .description .lost:focus {
  font-weight: bold;
  text-decoration: underline;
  color: #fa9c98;
}

.password-policy {
  text-align: left;
  font-family: Nunito Sans;
  font-size: 14px;
  width: 265px;
  line-height: 1.2;
  color: #000;
  padding: 10px 3px 0 6px;
}

.password-policy > div {
  margin: 10px 0;
  display: flex;
}

.password-policy > strong {
  font-weight: 700;
}

.ion-checkmark-round {
  height: 16px;
  width: 16px;
  margin: 0 10px 0 0;
  background-image: url(img/check-circle.svg);
}

.ion-checkmark-green {
  height: 16px;
  width: 16px;
  margin: 0 10px 0 0;
  background-image: url(img/green-check-circle.svg);
}

.password-tooltip {
  position: absolute;
  background: #fff;
  padding: 20px 18px 6px;
  margin: 16px 0 0;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  z-index: 100;
}

.triangle-with-shadow {
  width: 100px;
  height: 100px;
  position: absolute;
  overflow: hidden;
  top: -17px;
  left: -10px;
}

.triangle-with-shadow::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  left: 31px;
  top: 7px;
  box-shadow: -3px -3px 6px -1px rgba(0, 0, 0, 0.2);
}

.success-message {
  position: absolute;
  top: 50px;
  left: 50%;
}

.succes-message-body {
  position: relative;
  left: -50%;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  width: 588px;
}

.update-success-message-body {
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
}

.success-message .otp-link {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin: 3px auto;
}

.success-message .otp-group,
.update-success-message .otp-group {
  padding: 14px 0;
}

.success-message .otp-link-content,
.update-success-message .otp-link-content {
  opacity: 0.6;
  font-family: 'Nunito Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 3px auto;
}

.success-message .rectangle,
.update-success-message .rectangle {
  width: 6px;
  border-radius: 3px 0 0 3px;
  background-color: #d8e785;
}

.success-message .check-circle,
.update-success-message .check-circle {
  height: 24px;
  width: 24px;
  letter-spacing: 0;
  line-height: 24px;
  vertical-align: middle;
  margin: auto 12px;
}

.success-message .close {
  height: 26px;
  width: 20px;
  letter-spacing: 0;
  line-height: 21px;
  background-image: url(img/times.svg);
  background-size: cover;
  border: none;
  background-color: transparent;
  margin: auto 18px auto auto;
  cursor: pointer;
}

.otp-success .redirect-icon {
  font-size: 30px;
  text-align: center;
  padding: 20px;
  color: #fff;
}

.otp-page input[type='text']:disabled {
  opacity: 0.4;
}

@media (max-width: 540px) {
  .otp,
  .otp {
    padding: 10px 20px;
    margin: 10px;
  }
  .otp-page {
    min-height: 380px;
  }
  .succes-message-body {
    position: fixed;
    left: 2%;
    width: 520px;
  }
}

@media (max-width: 415px) {
  .succes-message-body {
    width: 394px;
  }
}

@media (max-width: 375px) {
  .succes-message-body {
    width: 355px;
  }
}

@media (max-width: 360px) {
  .succes-message-body {
    width: 340px;
  }
}

@media (max-width: 320px) {
  .succes-message-body {
    width: 300px;
  }
}
