@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,700');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.login-page {
  background: url(../tem-bg-full.jpg) no-repeat center center;
  min-height: 800px;
  font-weight: 300;
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
}

.login-page * {
  font-family: 'Nunito Sans';
  box-sizing: border-box;
}

.login {
  max-width: 588px;
  margin: 168px auto;
  background: rgba(255, 255, 255, 1);
  padding: 48px 60px;
  border-radius: 2px;
  border: none;
}

.message {
  color: #fb6a66;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  padding-top: 5px;
}

.member-login-content {
  color: #000000;
  font-family: 'Playfair Display', sans-serif;
  font-size: 36px;
  letter-spacing: 0;
  line-height: 42px;
  text-align: center;
  margin: 0 auto;
}

.member-login {
  margin-bottom: 31px;
}

.labelpair-login:not(:first-child) {
  margin-top: 25px;
}

.labelpair-login label {
  color: #000000;
  font-family: 'Nunito Sans';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.labelpair-login label span {
  color: #000000;
  font-weight: 700;
  margin-left: 5px;
}

.labelpair-login input {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  font-family: 'Nunito Sans';
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-top: 8px;
}

.labelpair-login-error input {
  box-sizing: border-box;
  border: 1px solid #cc484a;
  background-color: rgba(255, 91, 93, 0.05);
}

.labelpair-login input:focus {
  border: 1px solid #ccc;
}

.disclaimer {
  font-family: 'Nunito Sans';
  font-size: 12px;
  line-height: 16px;
  margin: 18px auto 0;
  text-align: center;
}
.disclaimer a {
  color: #3858cb;
  font-size: inherit;
  text-decoration: underline;
}
.disclaimer a:visited {
  color: #3858cb;
  font-size: inherit;
}
.disclaimer a:hover {
  color: #8ea7ff;
}
.disclaimer a:active {
  color: #4a72ff;
}

.login-page-button {
  margin-top: 24px;
  font-size: 16px;
  padding: 15px;
  text-transform: uppercase;
  background: #fb6a66;
  border: none;
  color: #fff;
  width: 100%;
  cursor: pointer;
  font-family: 'Nunito Sans';
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 18px;
}

.login-page-button:hover,
.login-page-button:focus {
  background: #fa9c98;
}

.links-login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 18px;
}

.links .check input:focus {
  outline: dotted;
  outline-color: #999;
  outline-width: thin;
}

.links label {
  display: inline-block;
  font-size: 14px;
  margin-left: 5px;
  color: #444;
}

.links-login a {
  display: inline-block;
  height: 18px;
  width: 122px;
  color: #666666;
  font-family: 'Nunito Sans';
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  -moz-transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  -webkit-transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
}

.message a {
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  margin-left: auto;
  color: #444;
  border-bottom: 1px solid #ddd;
  transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  -moz-transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  -webkit-transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
}

.message a {
  font-size: inherit;
}

.message a:hover,
.message a:focus,
.links-login a:hover,
.links-login a:focus {
  border-bottom: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.bottom-login {
  height: 24px;
  width: 200px;
  color: #000000;
  font-family: 'Nunito Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 30px auto 0 auto;
}

.bottom-login a {
  text-decoration: none;
  transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  -moz-transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  -webkit-transition: all 0.2s cubic-bezier(0.19, 0.98, 0.27, 0.95);
  color: #fb6a66;
  font-weight: bold;
}

.bottom-login a:hover,
.bottom-login a:focus {
  border-bottom: 1px solid #fa9c98;
  color: #fa9c98;
}

.reset-pwd-prompt {
  border: solid 2px rgb(246 178 66);
  border-radius: 8px;
  font-size: 14px;
  padding: 15px;
}

.reset-pwd-prompt .title {
  font-weight: 600;
  margin-bottom: 5px;
}

.reset-pwd-prompt .content {
  font-size: 13px;
}

.reset-pwd-prompt .content a {
  color: #fb6a66;
  text-decoration: none;
}

.reset-pwd-prompt .content a:hover,
.reset-pwd-prompt .content a:focus {
  color: #e2605c;
}

.reset-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}

.reset-btn .login-page-button {
  text-decoration: none;
  text-transform: none;
  font-size: 14px;
  padding: 8px 20px;
}

.note {
  font-size: 12px;
  color: gray;
  margin-top: 10px;
}

.note a {
  color: gray;
}

.login-page .login.valid {
  text-align: center;
}

.login-page .login.valid .title {
  font-size: x-large;
}

.valid-cookie-form {
  width: 300px;
  height: 267px;
  padding: 22px 8px 8px 8px;
  border-radius: 6px;
  border: solid 1px #ffdcd1;
  background-color: #ffffff;
  margin: 168px auto;
  text-align: center;
}

.valid-cookie-form .form-content {
  width: 232px;
  margin: 0 auto;
}

.valid-cookie-form .title-header {
  width: 210px;
  height: 60px;
  font-size: 24px;
  font-family: PlayfairDisplay;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.valid-cookie-form .title-body {
  margin-top: 5px;
}

.valid-cookie-form .title-body .title-body-span {
  width: 231px;
  height: 36px;
  margin-top: 5px;
  font-family: 'Nunito Sans', 'sans-serif';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.valid-cookie-form .button {
  width: 264px;
  height: 48px;
  margin-top: 12px;
  padding: 15px;
  cursor: pointer;
  text-transform: uppercase;
  border: thin solid #000000;
  background-color: #000000;
}

.valid-cookie-form .return-home {
  border: none;
}

.valid-cookie-form .return-home:hover,
.valid-cookie-form .return-home:focus {
  background: #4a4a4a;
}

.valid-cookie-form .customer-support {
  background: #ffffff;
}

.valid-cookie-form .customer-support:hover,
.valid-cookie-form .customer-support:focus {
  border: 1px solid #d0d0d0;
}

.valid-cookie-form .button-text {
  width: 232px;
  height: 18px;
  font-family: 'Nunito Sans', 'sans-serif';
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 700;
}

.valid-cookie-form .return {
  color: #ffffff;
}

.login-page .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

body.modal-open {
  overflow: hidden;
}

.hcaptcha {
  margin-top: 15px;
  text-align: center;
}

.login .field-icon {
  float: right;
  margin-top: -30px;
  margin-right: 12px;
  position: relative;
  z-index: 2;
}

.login .far {
  color: #9b9b9b;
  font-weight: 300 !important;
  font-family: 'Font Awesome 6 Pro';
}

@media (max-width: 480px) {
  .login {
    padding: 36px 24px;
    margin: 24px;
  }
  .login-page {
    min-height: 450px;
  }
}

@media (min-width: 768px) {
  .disclaimer {
    padding-left: 80px;
    padding-right: 80px;
  }
}
