#gtb.shared-button {
  padding: 13px 54px;
}

.route-button {
  padding: 20px;
}

.fff-header > .d-flex {
  padding: 3px;
}
